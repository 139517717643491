<template>
  <div class="page-events">
    <div class="page-events__game">
      <router-link tag="div" class="game-preview" to="mini-game">
        <div class="game-preview__title">
          {{ $t("game-title") }}
        </div>

        <div class="game-preview__notice">
          {{ $t("game-notice") }}
        </div>

        <button class="game-btn">
          {{ $t("game-btn") }}
        </button>
      </router-link>
    </div>
  </div>
</template>

<script>
import api from "@/api/api";
import formatNumberMixin from "@/mixins/common/FormatNumberMixin";
export default {
  name: "Events",
  mixins: [formatNumberMixin],
  data: () => ({
    isContestModalVisible: false,
    items: [],
    prizePool: 0,
  }),
  methods: {
    async getMainEvent() {
      try {
        const response = await api.getMainEvent();
        this.items = response.result;
        this.prizePool = +response.prizePool;
      } catch (e) {
        console.log(e);
      }
    },
  },
  async mounted() {
    await this.getMainEvent();
  },
};
</script>
